<template>
  <div class="prod-card">
    <section>
      <h5 v-text="title"></h5>
      <p v-text="desc"></p>
      <div class="more">
        <a @click="handleClick">了解更多>></a>
        <!-- <p><a @click="handleClick">了解更多>></a>
        </p> -->
      </div>
    </section>
    <div class="img-wrap" :class="imgSrc ? '' : 'empty'">
      <img class="img-card" v-if="imgSrc" :src="imgSrc" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "desc", "imgSrc", "id"],
  methods: {
    handleClick() {
      this.$router.push(`/production/detail/${this.id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.prod-card {
  background: #f2f2f2;
  text-align: center;
  position: relative;

  section {
    height: 40%;
    //Spadding: 20px;
    position: relative;
    margin: 0 auto;
    h5 {
      font-size: 22px;
      font-weight: 450;
      margin: 20px 0;
    }
    p {
      color: #999;
      font-size: 14px;
      margin: 10px;
      line-height: 2;
      height: 60px;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    a {
      cursor: pointer;
    }
    .more {
      //position: absolute;
      position: relative;
      color: #999;
      // bottom: 40px;
      //left: 50%;
      //transform: translate(-50%, -50%);
      display: block;

      p {
        font-size: 16px;
      }
    }
  }
  .img-wrap {
    background-color: #f9f9f9;
    height: 50%;
    width: calc(100% - 30px);
    position: absolute;
    bottom: 15px;
    margin: 0 15px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      aspect-ratio: auto 300/250;
    }
  }

  a:hover {
    color: orange;
  }

  .empty {
    background-image: url("../../assets/u117.png");
    background-position: center;
    background-repeat: no-repeat;
  }
}
</style>