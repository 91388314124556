<template>
  <div class="suspension">
    <div class="weixin">
      <div class="right-dia">
        <img src="../assets/微信.png" alt="" />
      </div>
      <div class="weixin-toast">
        <img :src="weixinSrc" alt="" />
      </div>
    </div>
    <hr />
    <div class="phone">
      <div class="right-dia">
        <img src="../assets/电话.png" alt="" />
      </div>
      <div class="phone-toast">
        <img :src="phoneSrc" alt="" />
        <!-- <span>{{ phoneSrc }}</span> -->
      </div>
    </div>
    <!-- <hr />
    <div class="qq">
      <div class="right-dia">
        <img src="../assets/QQ.png" alt="" />
      </div>
      <div class="qq-toast">
        <img :src="qqSrc" alt="" />
      </div>
    </div> -->
  </div>
</template>

<script>
import { get } from "../plugins/axios";
export default {
  data() {
    return {
      weixinSrc: "",
      phoneSrc: "",
      //qqSrc: "",
    };
  },
  mounted() {
    this.$nextTick(function () {
      get("/HomeApi/GetContactInfo")
        .then((data) => {
          console.log(data);
          this.weixinSrc = data.weixinImage;
          //this.qqSrc = data.qqImage;
          this.phoneSrc = data.phoneImage;
          //this.phoneSrc = "../assets/dhhm.png";
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },
};
</script>

<style lang='less' scoped>
.suspension {
  width: 60px;
  // height: 180px;
  height: 120px;
  background-color: #e4e7ed;
  z-index: 2000;
  float: right;
  position: fixed;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  .weixin {
    width: 60px;
    height: 60px;
    //background-color: aqua;
    .weixin-toast {
      display: none;
      position: fixed;
      right: 80px;
      background: rgb(255, 0, 0);
      box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
      //border-radius: 6px;
      width: 180px;
      height: 180px;
      line-height: 34px;
      top: 0px;
      bottom: 120px;
      margin: auto;
      font-size: 13px;
      float: right;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  // .phone {
  //   width: 60px;
  //   height: 60px;
  //   //background-color: rgb(212, 39, 53);
  //   .phone-toast {
  //     display: none;
  //     position: fixed;
  //     right: 80px;
  //     background: #fff;
  //     box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
  //     border-radius: 6px;
  //     width: 180px;
  //     height: 34px;
  //     top: 0px;
  //     bottom: 0px;
  //     margin: auto;
  //     font-size: 13px;
  //     float: right;
  //     text-align: center;
  //     span {
  //       line-height: 34px;
  //       margin: auto;
  //     }
  //   }
  // }
  .phone {
    width: 60px;
    height: 60px;
    //background-color: rgb(197, 241, 34);
    .phone-toast {
      display: none;
      position: fixed;
      right: 80px;
      background: #fff;
      box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
      //border-radius: 6px;
      width: 180px;
      height: 180px;
      line-height: 34px;
      top: 120px;
      bottom: 0px;
      margin: auto;
      font-size: 13px;
      float: right;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .qq {
    width: 60px;
    height: 60px;
    //background-color: rgb(197, 241, 34);
    .qq-toast {
      display: none;
      position: fixed;
      right: 80px;
      background: rgb(255, 0, 0);
      box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%);
      //border-radius: 6px;
      width: 180px;
      height: 180px;
      line-height: 34px;
      top: 120px;
      bottom: 0px;
      margin: auto;
      font-size: 13px;
      float: right;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.right-dia {
  width: 60px;
  height: 60px;
  text-align: center;
  img {
    height: 40px;
    width: 40px;
    margin-top: 10px;
  }
}
.weixin:hover .weixin-toast {
  display: block;
}
.phone:hover .phone-toast {
  display: block;
}
.qq:hover .qq-toast {
  display: block;
}
hr {
  margin: 0;
}
</style>