"use strict";

import axios from "axios";

const config = {
    withCredentials: false
};

axios.defaults.baseURL = "http://157.0.1.214:8016";

const _axios = axios.create(config);

export const get = function (...args) {
    return _axios
        .post(...args)
        .then(res => {
            const {
                data
            } = res
            return Promise.resolve(data)
        })
};