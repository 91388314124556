<template>
  <div>
    <banner></banner>
    <div class="home">
      <split-title
        :title="tabs.filter((f) => f.EnCode === 'JJFA')[0].Name"
        :desc="tabs.filter((f) => f.EnCode === 'JJFA')[0].Attr3"
      ></split-title>
      <div class="card-wrap">
        <solution-card
          v-for="(item, idx) in cards"
          :key="idx"
          :title="item.Name"
          :desc="item.SolutionDescription"
          :imgSrc="item.ImagePath"
          :id="item.Id"
        ></solution-card>
      </div>
      <split-title
        :title="tabs.filter((f) => f.EnCode === 'CPZX')[0].Name"
        :desc="tabs.filter((f) => f.EnCode === 'CPZX')[0].Attr3"
      ></split-title>
      <div class="prop-wrap">
        <production-card
          v-for="(item, idx) in prodCards"
          :key="idx"
          :title="item.Name"
          :desc="item.ProductDescription"
          :imgSrc="item.ImagePath"
          :id="item.Id"
        ></production-card>
      </div>
      <split-title
        :title="tabs.filter((f) => f.EnCode === 'ZXZX')[0].Name"
        :desc="tabs.filter((f) => f.EnCode === 'ZXZX')[0].Attr3"
      ></split-title>
      <div class="faq-wrap">
        <highcharts :options="chartOptions"></highcharts>
      </div>
      <div style="margin-top: 20px"></div>
    </div>
  </div>
</template>

<script>
import Banner from "../components/home/Banner.vue";
import SplitTitle from "../components/home/SplitTitle.vue";
import ProductionCard from "../components/production/ProductionCard.vue";
import SolutionCard from "../components/solution/SolutionCard.vue";
import { get } from "../plugins/axios";
import { dateFormat } from "../plugins/common.js";

export default {
  components: { Banner, SplitTitle, SolutionCard, ProductionCard },
  name: "Home",
  data() {
    return {
      chartOptions: {},
      cards: [],
      prodCards: [],
      faqCards: [],
      series: [],
      tabs: [
        {
          Name: "",
          Attr3: "",
        },
      ],
    };
  },
  mounted() {
    dateFormat,
      this.$nextTick(function () {
        get("/HomeApi/QueryHomeInfo")
          .then((data) => {
            this.cards = data.solutions;
            this.prodCards = data.products;
            this.faqCards = data.consolutings;
            this.tabs = data.tabs;
            this.faqCards.forEach((item) => {
              let time = dateFormat(
                item.Time === "" || item.Time === null
                  ? new Date()
                  : new Date(
                      parseInt(
                        String(item.Time)
                          .replace("/Date(", "")
                          .replace(")/", "")
                      )
                    )
              );
              this.series.push({
                name: item.Name,
                label: time + "：" + item.ConsultingDescription,
                description: item.ConsultingContent,
              });
            });

            this.chartOptions = {
              chart: {
                type: "timeline",
                width: 1200,
              },
              xAxis: {
                visible: false,
              },
              yAxis: {
                visible: false,
              },
              title: {
                text: "",
              },
              subtitle: {
                // text:
                //   '数据来源：<a href="https://en.wikipedia.org/wiki/Timeline_of_space_exploration">www.wikipedia.org</a>',
              },
              colors: [
                "#4185F3",
                "#427CDD",
                "#406AB2",
                "#3E5A8E",
                "#3B4A68",
                "#363C46",
                "#363C46",
              ],
              credits: {
                //去掉highcharts.com
                enabled: false,
              },
              series: [
                {
                  data: this.series,
                },
              ],
            };
          })
          .catch((e) => {
            console.log(e);
          });
      });
  },
};
</script>

<style lang="less" scoped>
.home {
  max-width: 1440px;
  min-width: 1260px;
  margin: 0 auto;
}
.split-title {
  margin-top: 20px;
}

.card-wrap {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(
    3,
    minmax(350px, 33.33%)
  ); /* minmax(33.33%, 33.33%) */
  padding: 30px 20px;
  grid-gap: 20px;

  justify-items: center;
  justify-content: center;

  .sol-card {
    width: 100%;
    height: 500px;
  }

  .prod-card {
    width: 100%;
    height: 400px;
  }
}

.prop-wrap {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, minmax(200px, 50%));
  padding: 30px 20px;
  grid-gap: 20px;

  .prod-card {
    min-width: 300px;
    height: 420px;
  }
}

.faq-wrap {
  margin: 0 auto;
  display: flex;
  //padding: 30px 20px;
  justify-items: center;
  justify-content: center;
  .prod-card {
    min-width: 300px;
    height: 400px;
  }
}
</style>
