<template>
  <div class="header">
    <div class="logo">
      <img :src="icon"/>
      <!-- <div class="tag">
        <span>纷云软件</span>
        <div class="tag-eng">
          <span>Fun</span>
          <span class="soft">soft</span>
        </div>
      </div> -->
    </div>
    <div class="nav">
      <div
        class="nav-item"
        v-for="(item, idx) in navs"
        :key="idx"
        @click="handleNav(item)"
      >
        <div
          v-text="item.Name"
          :class="
            $route.path === item.Attr1 || $route.meta.tag === item.Attr2
              ? 'actived'
              : ''
          "
        ></div>
      </div>
    </div>
    <!-- <div
      class="about nav-item"
      @click="handleNav({ route: '/about', tag: 'about' })"
    >
      <div class="about-us" :class="$route.path == '/about' ? 'actived' : ''">
        关于纷云
      </div>
    </div> -->
  </div>
</template>

<script>
import {get} from '../plugins/axios';
export default {
  data() {
    return {
      navs: [
        // {
        //   Name: "首页",
        //   Attr1: "/",
        //   Attr2: "home",
        // },
        // {
        //   Name: "解决方案",
        //   Attr1: "/solution",
        //   Attr2: "solution",
        // },
        // {
        //   Name: "产品中心",
        //   Attr1: "/production",
        //   Attr2: "production",
        // },
        // {
        //   Name: "资讯中心",
        //   Attr1: "/faq",
        //   Attr2: "faq",
        // },
        // {
        //   Name: "关于纷云",
        //   Attr1: "/about",
        //   Attr2: "about",
        // },
      ],
      icon:""
    };
  },
  methods: {
    handleNav(item) {
      // if (this.$route.path === item.Attr1 || this.$route.meta.tag === item.Attr2)
      //   return;
      this.$router.push(item.Attr1);
    },
  },
  mounted() {
    this.$nextTick(function() {
      get("/HomeApi/GetBaseInfo")
        .then((data) => {
          this.navs = data.navs;
          this.icon=data.baseinfo.EnterPriseLogoPath;
        })
        .catch((e) => {
          console.log(e);
        });
    });
    // this.axios.post('/HomeApi/Test').then(function(res){
    //   console.log(res)
    // }).catch(function(error){
    //   console.log(error)
    // })
  },
};
</script>

<style lang="less" scoped>
.header {
  height: 100px;
  user-select: none;
  min-width: 1260px;
  .logo {
    display: flex;
    height: 60px;
    font-size: 22px;
    font-weight: 700;
    top: 20px;
    position: relative;
    left: 20px;
    img {
      height: 100%;
    }
    .tag {
      height: 100%;
      display: flex;
      flex-flow: column;
      justify-content: center;
      row-gap: 8px;
      font-size: 18px;
      font-family: "Arial Negreta", "Arial Normal", "Arial";
      .tag-eng {
        font-style: italic;
        font-family: "Comic Sans MS Negreta cursiva", "Comic Sans MS Normal",
          "Comic Sans MS";
      }
      .soft {
        color: #6699ff;
      }
    }
  }

  .nav {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    align-items: center;
    min-width: 550px;
    max-width: 600px;
    padding-right: 40px;
  }

  .nav-item {
    cursor: pointer;
    padding: 5px;
  }

  .nav-item:hover {
    color: #fff;
    
  }

  .actived {
    color: orange;
    //text-decoration: underline;
    //text-underline-offset: 15px;
    font-weight: 600;
  }

  background:black;
  color: #ddd;
  font-size: 16px;
  font-weight: 400;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
}
</style>
