<template>
  <footer>
    <div class="glorys">
      <div v-for="(item, idx) in arry" :key="idx" class="glory">
        <div class="img">
          <img :src="item.ImagePath" />
        </div>
        <div>
          <p v-text="item.Name"></p>
          <p v-text="item.EnCode"></p>
        </div>
      </div>
    </div>
    <div>公司总部地址：{{ baseInfo.FullAddress }}</div>
    <!-- 江苏省淮安市经济开发区浙大网新29号楼纷云软件 -->
    <div>
      <span>Copyright © 2013-2021 funsoftware.cn 纷云软件 版权所有</span
      ><!--网站备案号：备案号: -->
      <a href="https://beian.miit.gov.cn" class="tiaozhuan">
        {{ baseInfo.WebsiteRecordNumber }}</a
      >
      <!-- 站长统计 -->
    </div>
    <!--苏ICP备10293819号-->
  </footer>
</template>

<script>
import { get } from "../plugins/axios";
export default {
  data() {
    return {
      arry: [
        // {
        //   icon: require("@/assets/u93.png"),
        //   label: "国家高新科技企业",
        //   no: "GR201743000396",
        // },
        // {
        //   icon: require("@/assets/u93.png"),
        //   label: "国家高新科技企业",
        //   no: "GR201743000396",
        // },
        // {
        //   icon: require("@/assets/u93.png"),
        //   label: "国家高新科技企业",
        //   no: "GR201743000396",
        // },
        // {
        //   icon: require("@/assets/u93.png"),
        //   label: "国家高新科技企业",
        //   no: "GR201743000396",
        // },
      ],
      baseInfo: {},
    };
  },
  // methods: {
  //   GotoYM() {
  //     window.open("https://beian.miit.gov.cn");
  //     //this.$router.push(`/faq/detail/${this.data.Id}`);
  //   },
  // },

  mounted() {
    this.$nextTick(function () {
      get("/HomeApi/QueryFooterInfo")
        .then((data) => {
          this.arry = data.certifications;
          this.baseInfo = data.baseInfo;
        })
        .catch((e) => {
          console.log(e);
        });
    });
  },
};
</script>

<style lang="less" scoped>
footer {
  background: #333;
  color: #fff;
  height: 200px;
  display: grid;
  grid-template-rows: 3fr 1fr 1fr;
  justify-content: center;
  text-align: center;
  .tiaozhuan {
    color: #fff;
    text-decoration: none;
  }

  .glorys {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    .glory {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .glory + .glory {
      margin-left: 20px;
    }
    .img {
      //border: 1px #fff solid;
      margin-right: 8px;
      margin-top: 5px;
      img {
        width: 55px;
        height: 55px;
      }
    }
  }
}
</style>