<template>
  <div class="split-title">
    <hr color="#987cb9" size="1" />
    <span v-text="title"></span>
    <hr color="#987cb9" size="1" />
    <p v-text="desc"></p>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    desc: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.split-title {
  text-align: center;
  height: 100px;
  padding-top: 20px;

  hr {
    width: calc(40% - 100px);
    display: inline-block;
  }

  span {
    width: 200px;
    display: inline-block;
    font-weight: 500;
    font-size: 24px;
  }

  p {
    font-size: 18px;
    color: rgb(153, 153, 153);
  }
}
</style>