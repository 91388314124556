<template>
  <div class="sol-card">
    <div class="img-wrap">
      <img class="img-card" v-if="imgSrc" :src="imgSrc" />
    </div>
    <section>
      <h5 v-text="title"></h5>
      <p v-text="desc" :title="this.desc"></p>
    </section>
    <button @click="more">了解更多>></button>
  </div>
</template>

<script>
export default {
  props: ["title", "desc", "imgSrc", "id"],
  data() {
    return {
      zhi: this.desc,
    };
  },
  methods: {
    more() {
      console.log(this.desc);
      this.$router.push(`/solution/detail/${this.id}`);
    },
  },
};
</script>

<style lang="less" scoped>
.img-wrap {
  background: #f2f2f2;
  height: 50%;
  .img-card {
    width: 100%;
    height: 100%;
  }
}

.sol-card {
  text-align: center;
  border: 1px solid #ccc;
  position: relative;
}

section {
  h5 {
    height: 58px;
    font-size: 22px;
    font-weight: 450;
    margin: 15px 0;
  }
  p {
    color: #999;
    font-size: 14px;
    margin: 10px;
    line-height: 2;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
}

button {
  background: rgba(0, 102, 204, 1);
  color: #fff;
  width: 140px;
  height: 38px;
  cursor: pointer;
  border: 0;
  font-size: 16px;
  outline: 0;
  position: absolute;
  bottom: 15px;
  left: calc(50% - 70px);
}

button:hover {
  color: orange;
}
</style>